import React, { useState } from 'react';
import './App.css';
import imagenEditada from './images/imagen-editada.png'; // Imagen editada
import corazones from './images/corazones1.png'; // Imagen o animación de corazones

const App = () => {
  const [showDownload, setShowDownload] = useState(false);
  const [showHearts, setShowHearts] = useState(false);

  const handleDownloadClick = () => {
    setShowDownload(true);
    setShowHearts(false); // Ocultamos corazones al mostrar el botón de descarga
    setTimeout(() => {
      setShowDownload(false); // Ocultamos el botón de descarga después de 5 segundos
    }, 5000);
  };

  const handleHeartsClick = () => {
    setShowHearts(true);
    setTimeout(() => setShowHearts(false), 3000); // Mostramos corazones por 3 segundos
  };

  return (
    <div className="container">
      <h1>¡Hola, doña tia!</h1>
      <p>Aquí tienes tu imagen editada, ¡espero que te guste! 😊</p>
      <img src={imagenEditada} alt="Imagen editada" className="image" />
      <div className="button-container">
        <button className="button-hearts" onClick={handleHeartsClick}>
          ❤️ ¡Gracias!
        </button>
        <button className="button-download" onClick={handleDownloadClick}>
          Descargar Imagen
        </button>
      </div>
      {showDownload && (
        <div className="download-message">
          <p>Haz clic abajo para descargar tu imagen:</p>
          <a href={imagenEditada} download="imagen-editada.png" className="download-link">
            Descargar Imagen Editada
          </a>
        </div>
      )}
      {showHearts && (
        <div className="hearts-animation">
          <img src={corazones} alt="Animación de corazones" className="hearts-image" />
        </div>
      )}
    </div>
  );
};

export default App;


